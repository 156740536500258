.btn-none {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
}

.btn-none:focus {
  outline: none;
}
