/* created using https://icomoon.io/app */
/* https://stackoverflow.com/a/35092005/681629 */
/* for additional icons, we should create a new set that includes the existing icons */

@font-face {
  font-family: 'nomad-icon';
  src: url('nomad-icon/nomad-icon.eot?6tre2n');
  src: url('nomad-icon/nomad-icon.eot?6tre2n#iefix') format('embedded-opentype'), url('nomad-icon/nomad-icon.ttf?6tre2n') format('truetype'),
    url('nomad-icon/nomad-icon.woff?6tre2n') format('woff'), url('nomad-icon/nomad-icon.svg?6tre2n#nomad-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.nomad-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nomad-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nomad-icon:before {
  content: '\e900';
}
