.root {
  display: flex;
  align-items: center;
  margin: 0;
}

.label {
  padding: 0;
}

.switchValues {
  font-style: normal;
  font-weight: 500;
  margin-left: 5px;
}
