.secondary-heading {
  background-color: transparent;
}

.secondary-body {
  background-color: transparent;
}

.datatable-wide {
  width: 55px;
}
