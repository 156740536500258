.menu-icon {
  background: var(--user-menu-icon-color);
  cursor: pointer;
  flex-shrink: 0;
}

.menu-icon::after {
  content: '';
  position: absolute;
  top: 8px;
  right: 10px;
  width: 6px;
  height: 6px;
  background: red;
  border-radius: 50%;
}
