:global(#page-wrapper) {
  padding-left: var(--sidebar-closed-width);
  transition: all 0.4s ease 0s;
}

@media only screen and (min-width: 561px) {
  :global(#page-wrapper.open) {
    padding-left: var(--sidebar-width);
  }
}
@media only screen and (max-width: 560px) {
  :global(#page-wrapper.open) {
    padding-left: var(--sidebar-closed-width);
  }
}

:global(#page-wrapper) {
  --sidebar-width: 300px;
  --sidebar-closed-width: 75px;
}

:global(#page-wrapper.open) .root {
  width: var(--sidebar-width);
}

.root {
  width: var(--sidebar-closed-width);
  height: 100%;

  position: fixed;
  left: 0;

  z-index: 999;
  transition: all 0.4s ease 0s;
  background-color: var(--bg-sidebar-color);
}

.root ul {
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  list-style: none;
}
