.form-control.limited-be {
  border-color: var(--BE-only);
}

.form-control.limited-be.no-border {
  border-color: var(--border-form-control-color);
}

button.limited-be,
button[disabled].limited-be.oauth-save-settings-button {
  background-color: var(--BE-only);
  border-color: var(--BE-only);
}

button.limited-be.oauth-save-settings-button {
  background-color: var(--blue-2);
  border-color: transparent;
}

ng-form.limited-be,
form.limited-be,
div.limited-be {
  border: solid 2px var(--BE-only);
  border-radius: 8px;
  pointer-events: none;
  touch-action: none;
  display: block;
}

.limited-be-content {
  background: rgba(247, 144, 9, 0.1);
  opacity: 0.5;
  padding: 10px;
}

.limited-be-link {
  z-index: 5;
  position: relative;
  width: 270px;
  height: 40px;
  top: 0px;
  right: 0px;
  float: right;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: var(--ui-warning-5);
  color: var(--ui-warning-9);
  padding: 5px 10px;
  touch-action: auto;
  cursor: hand;
  pointer-events: auto;
}

.limited-be-link a {
  cursor: hand;
  pointer-events: auto;
  color: var(--ui-warning-9);
}

.limited-be-link a:hover {
  text-decoration: underline;
  color: var(--ui-warning-9);
}

.overlay {
  background-image: url(../../assets/ico/lock.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.limited-be input,
.limited-be .widget-body {
  background: rgba(247, 144, 9, 0.05);
}

.form-control.limited-be[disabled] {
  background-color: transparent !important;
}
