.actionBar {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.actionBar .description {
  margin-bottom: 10px;
}

.refresh-environments-button {
  margin-left: 0 !important;
}

.filter-container {
  display: flex;
  justify-content: flex-start;
  padding: 0px 5px;
}

.filter-container input[type='text'] {
  background: none !important;
  border: 0px !important;
}

.filter-left {
  margin-left: 10px;
  padding: 10px 0px;
  width: 15%;
  display: inline-block;
}

.filter-right {
  padding: 10px;
  width: 20%;
  right: 0;
  display: inline-block;
  margin-left: auto;
}

.filter-button {
  margin-left: 10px;
  padding: 10px 0px;
  width: 5%;
  display: inline-block;
}

.clear-button {
  white-space: nowrap;

  border: 0px;
  padding: 5px 10px;

  background: transparent;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.refresh-button {
  display: inline-block;
  width: fit-content;
}

.kubeconfig-button {
  display: inline-block;
  width: fit-content;
}

.filterSearchbar {
  display: inline-block;
  width: 100%;
}

.filterSearchbar input[type='text'] {
  background: none !important;
  border: 0px !important;
}
